export const FLOOR_7_DATA = [
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 971,
    sceneName: 'scene_Lounatuuli_7krs_as971_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as971_A_future',
    name: '971',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      5: 951,
      6: 961,
      8: 981,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 972,
    sceneName: 'scene_Lounatuuli_7krs_as972_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as972_A_future',
    name: '972',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 932,
      4: 942,
      5: 952,
      6: 962,
      8: 982,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 973,
    sceneName: 'scene_Lounatuuli_7krs_as973_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as973_A_future',
    name: '973',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 923,
      3: 933,
      4: 943,
      5: 953,
      6: 963,
      8: 983,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 974,
    sceneName: 'scene_Lounatuuli_7krs_as974_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as974_A_future',
    name: '974',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 924,
      3: 934,
      4: 944,
      5: 954,
      6: 964,
      8: 984,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 975,
    sceneName: 'scene_Lounatuuli_7krs_as975_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as975_A_future',
    name: '975',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 935,
      4: 945,
      5: 955,
      6: 965,
      8: 985,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 976,
    sceneName: 'scene_Lounatuuli_7krs_as976_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as976_A_future',
    name: '976',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 936,
      4: 946,
      5: 956,
      6: 966,
      8: 986,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 977,
    sceneName: 'scene_Lounatuuli_7krs_as977_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as977_A_future',
    name: '977',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 937,
      4: 947,
      5: 957,
      6: 967,
      8: 987,
    },
  },
]
