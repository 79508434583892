import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor4({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  apartments,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="rakennus_8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 250 200"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="213.6205"
          y1="297.9413"
          x2="213.6205"
          y2="253.0313"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 361.3841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="305.966"
          y1="317.7957"
          x2="305.966"
          y2="272.8857"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="305.966"
          y1="293.7942"
          x2="305.966"
          y2="248.8842"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="305.966"
          y1="246.7913"
          x2="305.966"
          y2="201.8812"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="305.966"
          y1="187.7875"
          x2="305.966"
          y2="142.8775"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="213.6205"
          y1="206.9355"
          x2="213.6205"
          y2="162.0255"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="213.6205"
          y1="227.9369"
          x2="213.6205"
          y2="183.0268"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <rect className="cls-1" y="0.19" width="250" height="200" />
        <path
          id="bonava"
          className="cls-2"
          d="M195.6822,171.6379a1.45,1.45,0,0,0,.93-1.379c0-.9465-.7506-1.55-1.8767-1.55h-2.7824V174.73h2.7661c1.15,0,2.0318-.62,2.0318-1.6727A1.4165,1.4165,0,0,0,195.6822,171.6379Zm-2.7416-2.0236h1.6972c.563,0,.9792.2774.9792.8159s-.4325.8486-1.02.8486h-1.6564Zm1.7788,4.21h-1.7788v-1.6891l1.7706-.0082c.62,0,1.0445.2938,1.0445.84C195.7557,173.5309,195.3069,173.8247,194.7194,173.8247Zm6.6914-5.2059a3.1091,3.1091,0,1,0,2.88,3.0925A2.8707,2.8707,0,0,0,201.4108,168.6188Zm0,5.2793a2.2029,2.2029,0,1,1,1.8767-2.1786A1.9314,1.9314,0,0,1,201.4108,173.8981Zm8.8211-.84-2.9457-4.3491h-1.0771V174.73h.971v-4.52l3.0681,4.52h.9465v-6.0218h-.9628Zm5.1565-4.3491-2.4072,6.0218h1.02l.5141-1.3381h2.6029l.5141,1.3381h1.0526l-2.4071-6.0218Zm-.5222,3.7942.9547-2.4724.9546,2.4724Zm7.278.8078-1.6564-4.602h-1.069l2.2522,6.0218h.93l2.2358-6.0218h-1.0363Zm6.6661-4.602h-.8894l-2.4071,6.0218h1.02l.514-1.3381h2.603l.514,1.3381h1.0526Zm-1.4116,3.7942.9547-2.4724.9547,2.4724Zm-12.0926-9.0676a2.9637,2.9637,0,0,0-.91-2.1472l-.3559-.357.1048-.1048.0115-.0119a1.9923,1.9923,0,0,0,.5591-1.3982,2.0643,2.0643,0,0,0-2.0175-2.0453l-1.9941,0V159.2h.9675v-.8616h1.011a1.0914,1.0914,0,0,1,1.0655,1.0778,1.0252,1.0252,0,0,1-.2935.7322l-3.1786,3.1791v-2.3962h-.9676v2.504a2.8648,2.8648,0,0,0,.1027.7574l-1.5465,1.5467.6843.6841,1.3021-1.3022a3.0392,3.0392,0,0,0,2.4669,1.3028A2.9919,2.9919,0,0,0,215.3061,163.4352Zm-2.9882,2.0212a2.07,2.07,0,0,1-1.7706-1.0317l2.8088-2.8093.3558.3567.008.0079a2.0211,2.0211,0,0,1-1.402,3.4764Z"
        />
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(4, 945)}
          onMouseOver={() => setElementOnHover('945')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_4krs_as945_A'
                ? 'active-apt'
                : elementOnHover === '945'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '945') === 'sold' ? 'bg-gray' : ''
            }`}
            points="147.035 152.55 147.035 188.467 95.035 188.467 95.035 117.508 135.035 117.508 135.035 152.55 147.035 152.55"
          />
        </g>
        <g
          onClick={() => changeView(4, 946)}
          onMouseOver={() => setElementOnHover('946')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_4krs_as946_A'
                ? 'active-apt'
                : elementOnHover === '946'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '946') === 'sold' ? 'bg-gray' : ''
            }`}
            points="55.313 188.689 55.313 166.424 43.146 166.424 43.146 136.244 55.313 136.244 55.313 134.8 94.868 134.8 94.868 188.467 55.313 188.689"
          />
        </g>
        <g
          onClick={() => changeView(4, 947)}
          onMouseOver={() => setElementOnHover('947')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_4krs_as947_A'
                ? 'active-apt'
                : elementOnHover === '947'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '947') === 'sold' ? 'bg-gray' : ''
            }`}
            points="43.146 120.8 55.368 120.8 55.368 101.578 84.591 101.578 84.591 134.967 55.313 134.967 55.313 136.244 43.146 136.244 43.146 120.8"
          />
        </g>
        <g
          onClick={() => changeView(4, 944)}
          onMouseOver={() => setElementOnHover('944')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_4krs_as944_A'
                ? 'active-apt'
                : elementOnHover === '944'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '944') === 'sold' ? 'bg-gray' : ''
            }`}
            points="94.91 107.425 94.91 83.8 135.035 83.8 135.035 97.578 147.091 97.578 147.091 121.58 135.035 121.58 135.035 117.508 105.785 117.508 105.785 107.411 94.91 107.425"
          />
        </g>
        <g
          onClick={() => changeView(4, 943)}
          onMouseOver={() => setElementOnHover('943')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_4krs_as943_A'
                ? 'active-apt'
                : elementOnHover === '943'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '943') === 'sold' ? 'bg-gray' : ''
            }`}
            points="94.91 83.8 94.91 49.8 146.979 49.8 146.979 73.356 135.202 73.356 135.202 83.8 94.91 83.8"
          />
        </g>
        <g
          onClick={() => changeView(4, 942)}
          onMouseOver={() => setElementOnHover('942')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_4krs_as942_A'
                ? 'active-apt'
                : elementOnHover === '942'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '942') === 'sold' ? 'bg-gray' : ''
            }`}
            points="135.091 27.383 146.979 27.383 146.979 49.8 94.91 49.8 94.91 61.633 84.702 61.633 84.702 31.356 55.285 31.356 55.285 12.821 135.091 12.821 135.091 27.383"
          />
        </g>

        <g
          onClick={() => changeView(4, 941)}
          onMouseOver={() => setElementOnHover('941')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_4krs_as941_A'
                ? 'active-apt'
                : elementOnHover === '941'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '941') === 'sold' ? 'bg-gray' : ''
            }`}
            points="55.285 31.356 84.702 31.356 84.702 85.073 55.285 85.073 55.285 71.382 43.146 71.382 43.146 46.356 55.285 46.356 55.285 31.356"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          className="cls-4"
          points="55.313 188.467 135.091 188.467 135.091 12.8 55.313 12.8 55.313 71.382 55.313 188.467"
        />
        <line
          className="cls-5"
          x1="147.035"
          y1="152.55"
          x2="135.0906"
          y2="152.55"
        />
        <line
          className="cls-5"
          x1="135.0906"
          y1="188.4667"
          x2="147.035"
          y2="188.4667"
        />
        <polyline
          className="cls-5"
          points="94.868 188.467 94.868 117.508 135.091 117.508"
        />
        <line
          className="cls-6"
          x1="147.035"
          y1="188.9667"
          x2="147.035"
          y2="152.1611"
        />
        <line
          className="cls-6"
          x1="147.035"
          y1="122.0022"
          x2="147.035"
          y2="97.2583"
        />
        <polyline
          className="cls-5"
          points="94.868 117.508 94.868 107.411 105.785 107.411 105.785 117.411"
        />
        <polyline
          className="cls-5"
          points="55.313 101.578 84.757 101.578 84.757 134.55"
        />
        <line
          className="cls-5"
          x1="67.6461"
          y1="93.3556"
          x2="80.3128"
          y2="93.3556"
        />
        <line
          className="cls-7"
          x1="55.3128"
          y1="166.2444"
          x2="43.1461"
          y2="166.2444"
        />
        <line
          className="cls-7"
          x1="55.3128"
          y1="120.7444"
          x2="43.1461"
          y2="120.7444"
        />
        <line
          className="cls-7"
          x1="55.3128"
          y1="136.0778"
          x2="43.1461"
          y2="136.0778"
        />
        <polyline
          className="cls-5"
          points="94.868 134.883 84.757 134.883 55.313 134.883"
        />
        <line
          className="cls-5"
          x1="43.1461"
          y1="71.3816"
          x2="55.3128"
          y2="71.3816"
        />
        <line
          className="cls-5"
          x1="55.3128"
          y1="46.3556"
          x2="43.1461"
          y2="46.3556"
        />
        <line
          className="cls-5"
          x1="135.0906"
          y1="121.58"
          x2="147.035"
          y2="121.58"
        />
        <line
          className="cls-7"
          x1="135.0906"
          y1="97.6333"
          x2="147.035"
          y2="97.6333"
        />
        <line
          className="cls-7"
          x1="135.0906"
          y1="73.22"
          x2="147.035"
          y2="73.22"
        />
        <line
          className="cls-7"
          x1="135.0906"
          y1="27.3833"
          x2="147.035"
          y2="27.3833"
        />
        <polyline
          className="cls-7"
          points="94.868 107.411 94.868 49.8 135.091 49.8 147.035 49.8"
        />
        <polyline
          className="cls-4"
          points="167.625 3.612 167.625 12.821 78.618 12.821 78.618 3.612"
        />
        <line
          className="cls-6"
          x1="43.1461"
          y1="45.9314"
          x2="43.1461"
          y2="71.8063"
        />
        <line
          className="cls-6"
          x1="43.1461"
          y1="120.3698"
          x2="43.1461"
          y2="166.6196"
        />
        <line
          className="cls-6"
          x1="147.035"
          y1="73.5813"
          x2="147.035"
          y2="27.0118"
        />
        <line
          className="cls-7"
          x1="94.8683"
          y1="83.8"
          x2="135.0906"
          y2="83.8"
        />
        <polyline
          className="cls-7"
          points="55.313 85.073 84.757 85.073 84.757 31.356 55.313 31.356"
        />
        <line
          className="cls-7"
          x1="84.7572"
          y1="61.4667"
          x2="95.2017"
          y2="61.4667"
        />
        <line
          className="cls-5"
          x1="58.7017"
          y1="85.0222"
          x2="58.7017"
          y2="101.5778"
        />
      </g>
      <g id="view">
        {apartmentNumber === 941 && (
          <g id="_941" data-name="941">
            <path
              id="cone"
              className="cls-11"
              d="M52.0923,63.4628a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 942 && (
          <g id="_942" data-name="942">
            <path
              id="cone-2"
              data-name="cone"
              className="cls-12"
              d="M144.4378,43.6083a7.4914,7.4914,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 943 && (
          <g id="_943" data-name="943">
            <path
              id="cone-3"
              data-name="cone"
              className="cls-13"
              d="M144.4378,67.61a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 944 && (
          <g id="_944" data-name="944">
            <path
              id="cone-4"
              data-name="cone"
              className="cls-14"
              d="M144.4378,114.6128a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 945 && (
          <g id="_945" data-name="945">
            <path
              id="cone-5"
              data-name="cone"
              className="cls-15"
              d="M144.4378,173.6165a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 946 && (
          <g id="_946" data-name="946">
            <path
              id="cone-6"
              data-name="cone"
              className="cls-16"
              d="M52.0923,154.4685a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-6"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
        {apartmentNumber === 947 && (
          <g id="_947" data-name="947">
            <path
              id="cone-7"
              data-name="cone"
              className="cls-17"
              d="M52.0923,133.4672a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-7"
              data-name="location"
              className="cls-1"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        )}
      </g>
      <g id="elevator">
        <path
          id="plate"
          className="cls-2"
          d="M212.7418,56.92a15.1463,15.1463,0,0,0-15.15,15.15v56.24a15.15,15.15,0,1,0,30.3,0V72.07A15.1463,15.1463,0,0,0,212.7418,56.92Z"
        />
        <path
          className="cls-8"
          d="M212.7418,88.67a11.52,11.52,0,1,0,11.52,11.52A11.523,11.523,0,0,0,212.7418,88.67Z"
        />
        <path
          className="cls-9"
          d="M212.787,104.4133V102.466h-3.3223v-.6377l3.19-4.5659H213.7v4.4448h1.001v.7588H213.7v1.9473Zm0-2.7061V99.32q0-.561.0332-1.122h-.0332c-.22.4179-.3956.726-.5938,1.0561l-1.749,2.4312v.0224Z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-10"
            d="M212.7368,135.36a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-10"
            d="M212.7368,121.58a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,127.145l.3975.53-4.505,3.0475a.403.403,0,0,1-.53,0l-4.5049-3.0475.3975-.53,4.24,2.7825a.2484.2484,0,0,0,.3975,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-10"
            d="M212.7368,65.005a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-10"
            d="M212.7368,78.785a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,73.22l.3975-.53-4.505-3.0475a.403.403,0,0,0-.53,0L208.0994,72.69l.3975.53,4.24-2.7825a.2484.2484,0,0,1,.3975,0Z"
          />
        </g>
      </g>
      <g id="apartmentnr">
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_4krs_as941_A'
              ? 'white-text'
              : elementOnHover === '941'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '941') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(4, 941)}
          onMouseOver={() => setElementOnHover('941')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M65.3944,60.3654a2.5371,2.5371,0,0,0,.4961-.02,1.7192,1.7192,0,0,0,.9531-.4414,1.9986,1.9986,0,0,0,.6025-1.2119h-.02a1.3374,1.3374,0,0,1-1.0722.49,1.3186,1.3186,0,0,1-1.3448-1.3935A1.5617,1.5617,0,0,1,66.5633,56.17c.9317,0,1.5059.7569,1.5059,1.919a2.8778,2.8778,0,0,1-.7842,2.1367,2.2823,2.2823,0,0,1-1.3242.6094,2.8647,2.8647,0,0,1-.5664.0351Zm.2236-2.6191a.8759.8759,0,0,0,.876.9668,1.021,1.021,0,0,0,.8965-.4766.4044.4044,0,0,0,.0556-.2236c0-.7774-.2871-1.3731-.9316-1.3731C65.9891,56.64,65.618,57.1018,65.618,57.7463Z"
          />
          <path
            className="cls-2"
            d="M70.5331,60.8V59.5607H68.4178v-.4062l2.0313-2.9072h.664v2.83h.6387v.4834h-.6387V60.8Zm0-1.7227v-1.52c0-.2373.0058-.4755.02-.7138h-.02c-.1407.2656-.252.4619-.379.6718l-1.1132,1.5479v.0146Z"
          />
          <path
            className="cls-2"
            d="M73.5565,56.8215h-.0137l-.791.4277-.1191-.47.9941-.5322h.5254V60.8h-.5957Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_Lounatuuli_4krs_as945_A'
              ? 'white-text'
              : elementOnHover === '945'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '945') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(4, 945)}
          onMouseOver={() => setElementOnHover('945')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M108.25,171.2209a2.5546,2.5546,0,0,0,.4971-.02,1.7189,1.7189,0,0,0,.9531-.4414,2,2,0,0,0,.6026-1.2119h-.0215a1.3341,1.3341,0,0,1-1.0713.49,1.3186,1.3186,0,0,1-1.3447-1.3935,1.5609,1.5609,0,0,1,1.5547-1.6182c.9316,0,1.5058.7568,1.5058,1.9189a2.8775,2.8775,0,0,1-.7842,2.1368,2.2845,2.2845,0,0,1-1.3242.6093,2.8713,2.8713,0,0,1-.5674.0352Zm.2247-2.6191a.8757.8757,0,0,0,.875.9668,1.0187,1.0187,0,0,0,.8964-.4766.4035.4035,0,0,0,.0567-.2236c0-.7774-.2871-1.3731-.9317-1.3731C108.8456,167.4953,108.4745,167.9572,108.4745,168.6018Z"
          />
          <path
            className="cls-2"
            d="M113.3885,171.6555v-1.2393h-2.1152V170.01l2.0312-2.9073h.6651v2.83h.6377v.4834H113.97v1.2393Zm0-1.7227v-1.52q0-.3559.0215-.7139h-.0215c-.14.2657-.2519.462-.3779.6719l-1.1133,1.5479v.0146Z"
          />
          <path
            className="cls-2"
            d="M117.7918,167.6213h-1.7363l-.1758,1.17a2.3634,2.3634,0,0,1,.3721-.0283,1.9268,1.9268,0,0,1,.98.2451,1.3,1.3,0,0,1,.6513,1.17,1.58,1.58,0,0,1-1.6953,1.5547,2.2351,2.2351,0,0,1-1.1347-.28l.1543-.4687a2.05,2.05,0,0,0,.9736.2519,1.0048,1.0048,0,0,0,1.0781-.9882c-.0068-.5879-.3984-1.0078-1.31-1.0078a4.6286,4.6286,0,0,0-.63.0488l.2939-2.1856h2.1777Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_4krs_as944_A'
              ? 'white-text'
              : elementOnHover === '944'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '944') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(4, 944)}
          onMouseOver={() => setElementOnHover('944')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M108.25,102.2561a2.5479,2.5479,0,0,0,.4971-.0206,1.7182,1.7182,0,0,0,.9531-.4414,1.9989,1.9989,0,0,0,.6026-1.2119h-.0215a1.3342,1.3342,0,0,1-1.0713.49,1.3186,1.3186,0,0,1-1.3447-1.3936,1.561,1.561,0,0,1,1.5547-1.6182c.9316,0,1.5058.7569,1.5058,1.919a2.8774,2.8774,0,0,1-.7842,2.1367,2.2846,2.2846,0,0,1-1.3242.6094,2.87,2.87,0,0,1-.5674.0351Zm.2247-2.6192a.8757.8757,0,0,0,.875.9668,1.0186,1.0186,0,0,0,.8964-.4765.4036.4036,0,0,0,.0567-.2237c0-.7773-.2871-1.373-.9317-1.373C108.8456,98.5305,108.4745,98.9924,108.4745,99.6369Z"
          />
          <path
            className="cls-2"
            d="M113.3885,102.6906v-1.2392h-2.1152v-.4063l2.0312-2.9072h.6651v2.83h.6377v.4834H113.97v1.2392Zm0-1.7226v-1.52q0-.356.0215-.7139h-.0215c-.14.2656-.2519.4619-.3779.6719l-1.1133,1.5478v.0147Z"
          />
          <path
            className="cls-2"
            d="M116.9793,102.6906v-1.2392h-2.1142v-.4063l2.03-2.9072h.666v2.83h.6367v.4834h-.6367v1.2392Zm0-1.7226v-1.52c0-.2373.0079-.4756.0215-.7139h-.0215c-.14.2656-.2519.4619-.3779.6719l-1.1133,1.5478v.0147Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_4krs_as943_A'
              ? 'white-text'
              : elementOnHover === '943'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '943') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(4, 943)}
          onMouseOver={() => setElementOnHover('943')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M108.25,65.6086a2.5546,2.5546,0,0,0,.4971-.02,1.7189,1.7189,0,0,0,.9531-.4414,2,2,0,0,0,.6026-1.2119h-.0215a1.3341,1.3341,0,0,1-1.0713.49,1.3186,1.3186,0,0,1-1.3447-1.3936,1.5609,1.5609,0,0,1,1.5547-1.6181c.9316,0,1.5058.7568,1.5058,1.9189a2.8771,2.8771,0,0,1-.7842,2.1367,2.284,2.284,0,0,1-1.3242.6094,2.8713,2.8713,0,0,1-.5674.0352Zm.2247-2.6191a.8757.8757,0,0,0,.875.9668,1.0187,1.0187,0,0,0,.8964-.4766.4035.4035,0,0,0,.0567-.2236c0-.7774-.2871-1.3731-.9317-1.3731C108.8456,61.883,108.4745,62.3449,108.4745,62.99Z"
          />
          <path
            className="cls-2"
            d="M113.3885,66.0432V64.8039h-2.1152v-.4062l2.0312-2.9073h.6651v2.83h.6377v.4834H113.97v1.2393Zm0-1.7227V62.8q0-.3559.0215-.7139h-.0215c-.14.2657-.2519.4619-.3779.6719l-1.1133,1.5479V64.32Z"
          />
          <path
            className="cls-2"
            d="M115.2215,65.3361a2.0727,2.0727,0,0,0,1.0088.2871c.792,0,1.0371-.5048,1.0293-.8828-.0068-.6377-.581-.91-1.1767-.91H115.74v-.4629h.3428c.4482,0,1.0156-.2305,1.0156-.7705,0-.3643-.2305-.6856-.7979-.6856a1.6787,1.6787,0,0,0-.9111.3008l-.16-.4482a2.1635,2.1635,0,0,1,1.1905-.3506c.8965,0,1.3027.5322,1.3027,1.0859a1.13,1.13,0,0,1-.8408,1.0713v.0147a1.1849,1.1849,0,0,1,1.0156,1.1689c0,.7285-.5674,1.3662-1.66,1.3662a2.3122,2.3122,0,0,1-1.1836-.3086Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_4krs_as942_A'
              ? 'white-text'
              : elementOnHover === '942'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '942') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(4, 942)}
          onMouseOver={() => setElementOnHover('942')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M108.25,34.39a2.5467,2.5467,0,0,0,.4971-.02,1.7189,1.7189,0,0,0,.9531-.4414,1.9989,1.9989,0,0,0,.6026-1.2119h-.0215a1.3342,1.3342,0,0,1-1.0713.49,1.3187,1.3187,0,0,1-1.3447-1.3936,1.561,1.561,0,0,1,1.5547-1.6182c.9316,0,1.5058.7569,1.5058,1.919a2.8774,2.8774,0,0,1-.7842,2.1367,2.2846,2.2846,0,0,1-1.3242.6094,2.87,2.87,0,0,1-.5674.0351Zm.2247-2.6191a.8757.8757,0,0,0,.875.9668,1.0185,1.0185,0,0,0,.8964-.4766.403.403,0,0,0,.0567-.2236c0-.7773-.2871-1.373-.9317-1.373C108.8456,30.6643,108.4745,31.1262,108.4745,31.7707Z"
          />
          <path
            className="cls-2"
            d="M113.3885,34.8244V33.5852h-2.1152v-.4063l2.0312-2.9072h.6651v2.83h.6377v.4834H113.97v1.2392Zm0-1.7226v-1.52q0-.3561.0215-.7139h-.0215c-.14.2656-.2519.4619-.3779.6719l-1.1133,1.5478v.0147Z"
          />
          <path
            className="cls-2"
            d="M115.075,34.8244v-.3779l.4834-.47c1.1622-1.1065,1.6875-1.6944,1.6944-2.3809a.8117.8117,0,0,0-.9033-.89,1.5524,1.5524,0,0,0-.9668.3847l-.1963-.4336a1.9965,1.9965,0,0,1,1.289-.4629,1.2812,1.2812,0,0,1,1.3936,1.3243c0,.8408-.6094,1.52-1.5684,2.4443l-.3642.3359v.0137h2.0449v.5117Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_4krs_as947_A'
              ? 'white-text'
              : elementOnHover === '947'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '947') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(4, 947)}
          onMouseOver={() => setElementOnHover('947')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M65.3944,121.1457a2.5371,2.5371,0,0,0,.4961-.02,1.7192,1.7192,0,0,0,.9531-.4414,1.9986,1.9986,0,0,0,.6025-1.2119h-.02a1.3374,1.3374,0,0,1-1.0722.49,1.3186,1.3186,0,0,1-1.3448-1.3935,1.5616,1.5616,0,0,1,1.5547-1.6182c.9317,0,1.5059.7568,1.5059,1.9189a2.8779,2.8779,0,0,1-.7842,2.1368,2.2822,2.2822,0,0,1-1.3242.6093,2.8658,2.8658,0,0,1-.5664.0352Zm.2236-2.6191a.876.876,0,0,0,.876.9668,1.0212,1.0212,0,0,0,.8965-.4766.4047.4047,0,0,0,.0556-.2236c0-.7774-.2871-1.3731-.9316-1.3731C65.9891,117.42,65.618,117.882,65.618,118.5266Z"
          />
          <path
            className="cls-2"
            d="M70.5331,121.58V120.341H68.4178v-.4062l2.0313-2.9073h.664v2.83h.6387v.4834h-.6387V121.58Zm0-1.7227v-1.52c0-.2373.0058-.4756.02-.7139h-.02c-.1407.2657-.252.462-.379.6719l-1.1132,1.5479v.0146Z"
          />
          <path
            className="cls-2"
            d="M75.1737,117.0275v.4063l-1.98,4.1465h-.6387l1.9746-4.0274v-.0136H72.3026v-.5118Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_4krs_as946_A'
              ? 'white-text'
              : elementOnHover === '946'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '946') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(4, 946)}
          onMouseOver={() => setElementOnHover('946')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M69.8241,152.5529a2.548,2.548,0,0,0,.497-.02,1.7131,1.7131,0,0,0,.9522-.4414,1.9943,1.9943,0,0,0,.6025-1.2119h-.02a1.3374,1.3374,0,0,1-1.0722.49,1.3186,1.3186,0,0,1-1.3448-1.3935,1.5616,1.5616,0,0,1,1.5547-1.6182c.9317,0,1.5059.7569,1.5059,1.919a2.8778,2.8778,0,0,1-.7842,2.1367,2.28,2.28,0,0,1-1.3233.6094,2.8772,2.8772,0,0,1-.5673.0351Zm.2236-2.6191a.8764.8764,0,0,0,.876.9668,1.0186,1.0186,0,0,0,.8965-.4766.4044.4044,0,0,0,.0556-.2236c0-.7774-.2871-1.3731-.9316-1.3731C70.4188,148.8273,70.0477,149.2893,70.0477,149.9338Z"
          />
          <path
            className="cls-2"
            d="M74.9627,152.9875v-1.2393H72.8475v-.4062l2.0313-2.9072h.665v2.83h.6377v.4834h-.6377v1.2393Zm0-1.7227v-1.52q0-.356.02-.7138h-.02c-.1406.2656-.2519.4619-.3789.6718L73.4706,151.25v.0146Z"
          />
          <path
            className="cls-2"
            d="M79.2469,148.8693a2.2122,2.2122,0,0,0-.4619.0284,1.85,1.85,0,0,0-1.583,1.6171h.0215a1.3878,1.3878,0,0,1,2.4726.9463,1.5225,1.5225,0,0,1-1.5136,1.6036c-.9737,0-1.61-.7569-1.61-1.94a2.8614,2.8614,0,0,1,.77-2.0517A2.4788,2.4788,0,0,1,78.8,148.4a3.0269,3.0269,0,0,1,.4472-.0342Zm-.1748,2.626a.9245.9245,0,0,0-.9385-1.043,1.0118,1.0118,0,0,0-.8818.5606.51.51,0,0,0-.07.2725c.0137.75.3574,1.3027,1.001,1.3027C78.7147,152.5881,79.0721,152.1467,79.0721,151.4953Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  941: [49.3223, 58.8628],
  942: [141.6678, 39.0083],
  943: [141.6678, 63.0099],
  944: [141.6678, 110.0128],
  945: [141.6678, 169.0165],
  946: [49.3223, 149.8685],
  947: [49.3223, 128.8672],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #86cfc2;
  }

  .cls-2,
  .cls-9 {
    fill: #004a33;
  }

  .cls-3 {
    fill: #537f6e;
  }

  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7 {
    fill: none;
  }

  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-9 {
    stroke: #004a33;
    stroke-miterlimit: 10;
  }

  .cls-4 {
    stroke-width: 1.5px;
  }

  .cls-5 {
    stroke-width: 0.85px;
  }

  .cls-6 {
    stroke-width: 0.3px;
  }

  .cls-7,
  .cls-9 {
    stroke-width: 0.75px;
  }

  .cls-8 {
    fill: #a5dacf;
  }

  .cls-10 {
    fill: #65c5b4;
  }

  .cls-11 {
    fill: url(#linear-gradient);
  }

  .cls-12 {
    fill: url(#linear-gradient-2);
  }

  .cls-13 {
    fill: url(#linear-gradient-3);
  }

  .cls-14 {
    fill: url(#linear-gradient-4);
  }

  .cls-15 {
    fill: url(#linear-gradient-5);
  }

  .cls-16 {
    fill: url(#linear-gradient-6);
  }

  .cls-17 {
    fill: url(#linear-gradient-7);
  }

  #up:hover,
  #down:hover {
    cursor: pointer;

    .cls-10 {
      fill: var(--secondary-color);
    }

    .cls-2 {
      fill: var(--green-color);
    }
  }

  .disable-lift-btn {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--grey);
    }

    .cls-6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text .cls-2 {
    fill: var(--light);
    cursor: pointer;
  }

  .green-text .cls-2 {
    fill: var(--green-color);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }
`
