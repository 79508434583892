export const FLOOR_8_DATA = [
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 981,
    sceneName: 'scene_Lounatuuli_8krs_as981_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as981_A_future',
    name: '981',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      5: 951,
      6: 961,
      7: 971,
    },
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 982,
    sceneName: 'scene_Lounatuuli_8krs_as982_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as982_A_future',
    name: '982',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 932,
      4: 942,
      5: 952,
      6: 962,
      7: 972,
    },
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 983,
    sceneName: 'scene_Lounatuuli_8krs_as983_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as983_A_future',
    name: '983',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 923,
      3: 933,
      4: 943,
      5: 953,
      6: 963,
      7: 973,
    },
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 984,
    sceneName: 'scene_Lounatuuli_8krs_as984_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as984_A_future',
    name: '984',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 924,
      3: 934,
      4: 944,
      5: 954,
      6: 964,
      7: 974,
    },
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 985,
    sceneName: 'scene_Lounatuuli_8krs_as985_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as985_A_future',
    name: '985',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 935,
      4: 945,
      5: 955,
      6: 965,
      7: 975,
    },
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 986,
    sceneName: 'scene_Lounatuuli_8krs_as986_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as986_A_future',
    name: '986',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 936,
      4: 946,
      5: 956,
      6: 966,
      7: 976,
    },
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 987,
    sceneName: 'scene_Lounatuuli_8krs_as987_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as987_A_future',
    name: '987',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 937,
      4: 947,
      5: 957,
      6: 967,
      7: 977,
    },
  },
]
