import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor1({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  apartments,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="rakennus_9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 250 200"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="305.966"
          y1="187.7875"
          x2="305.966"
          y2="142.8775"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 361.3841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g id="bg">
        <rect className="cls-1" y="0.19" width="250" height="200" />
        <path
          id="bonava"
          className="cls-2"
          d="M195.6822,171.6379a1.45,1.45,0,0,0,.93-1.379c0-.9465-.7506-1.55-1.8767-1.55h-2.7824V174.73h2.7661c1.15,0,2.0318-.62,2.0318-1.6727A1.4165,1.4165,0,0,0,195.6822,171.6379Zm-2.7416-2.0236h1.6972c.563,0,.9792.2774.9792.8159s-.4325.8486-1.02.8486h-1.6564Zm1.7788,4.21h-1.7788v-1.6891l1.7706-.0082c.62,0,1.0445.2938,1.0445.84C195.7557,173.5309,195.3069,173.8247,194.7194,173.8247Zm6.6914-5.2059a3.1091,3.1091,0,1,0,2.88,3.0925A2.8707,2.8707,0,0,0,201.4108,168.6188Zm0,5.2793a2.2029,2.2029,0,1,1,1.8767-2.1786A1.9314,1.9314,0,0,1,201.4108,173.8981Zm8.8211-.84-2.9457-4.3491h-1.0771V174.73h.971v-4.52l3.0681,4.52h.9465v-6.0218h-.9628Zm5.1565-4.3491-2.4072,6.0218h1.02l.5141-1.3381h2.6029l.5141,1.3381h1.0526l-2.4071-6.0218Zm-.5222,3.7942.9547-2.4724.9546,2.4724Zm7.278.8078-1.6564-4.602h-1.069l2.2522,6.0218h.93l2.2358-6.0218h-1.0363Zm6.6661-4.602h-.8894l-2.4071,6.0218h1.02l.514-1.3381h2.603l.514,1.3381h1.0526Zm-1.4116,3.7942.9547-2.4724.9547,2.4724Zm-12.0926-9.0676a2.9637,2.9637,0,0,0-.91-2.1472l-.3559-.357.1048-.1048.0115-.0119a1.9923,1.9923,0,0,0,.5591-1.3982,2.0643,2.0643,0,0,0-2.0175-2.0453l-1.9941,0V159.2h.9675v-.8616h1.011a1.0914,1.0914,0,0,1,1.0655,1.0778,1.0252,1.0252,0,0,1-.2935.7322l-3.1786,3.1791v-2.3962h-.9676v2.504a2.8648,2.8648,0,0,0,.1027.7574l-1.5465,1.5467.6843.6841,1.3021-1.3022a3.0392,3.0392,0,0,0,2.4669,1.3028A2.9919,2.9919,0,0,0,215.3061,163.4352Zm-2.9882,2.0212a2.07,2.07,0,0,1-1.7706-1.0317l2.8088-2.8093.3558.3567.008.0079a2.0211,2.0211,0,0,1-1.402,3.4764Z"
        />
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(1, 911)}
          onMouseOver={() => setElementOnHover('911')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            className={`cls-3 ${
              sceneName === 'scene_Lounatuuli_1krs_as911_A'
                ? 'active-apt'
                : elementOnHover === '911'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, '911') === 'sold' ? 'bg-gray' : ''
            }`}
            points="147.035 152.55 147.035 188.467 95.035 188.467 95.035 117.508 135.035 117.508 135.035 152.55 147.035 152.55"
          />
        </g>
      </g>
      <g id="levelfloor">
        <rect
          className="cls-4"
          x="55.3128"
          y="12.8"
          width="79.7778"
          height="175.6667"
        />
        <line
          className="cls-5"
          x1="147.035"
          y1="152.55"
          x2="135.0906"
          y2="152.55"
        />
        <line
          className="cls-5"
          x1="135.0906"
          y1="188.4667"
          x2="147.035"
          y2="188.4667"
        />
        <polyline
          className="cls-5"
          points="94.868 188.467 94.868 134.8 94.868 117.508 135.091 117.508"
        />
        <line
          className="cls-6"
          x1="147.035"
          y1="188.9667"
          x2="147.035"
          y2="152.1611"
        />
        <polyline
          className="cls-5"
          points="94.868 117.508 94.868 107.411 105.785 107.411 105.785 117.411"
        />
        <polyline
          className="cls-5"
          points="55.313 85.022 84.757 85.022 84.757 83.8 94.868 83.8 94.868 97.55 135.091 97.55"
        />
        <line
          className="cls-5"
          x1="67.6461"
          y1="93.3556"
          x2="80.3128"
          y2="93.3556"
        />
        <polyline
          className="cls-4"
          points="167.625 3.612 167.625 12.821 78.618 12.821 78.618 3.612"
        />
        <polyline
          className="cls-5"
          points="55.313 101.578 84.757 101.578 84.695 134.883 94.806 134.883"
        />
        <line
          className="cls-5"
          x1="105.785"
          y1="107.4111"
          x2="135.0906"
          y2="107.4111"
        />
      </g>
      <g id="view">
        <g id="_911" data-name="911">
          <path
            id="cone"
            className="cls-10"
            d="M144.4378,173.6165a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
            transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
          />
          <circle
            id="location"
            className="cls-1"
            cx={`${pivots[apartmentNumber][0]}`}
            cy={`${pivots[apartmentNumber][1]}`}
            r="1.66"
          />
        </g>
      </g>
      <g id="elevator">
        <path
          id="plate"
          className="cls-2"
          d="M212.7418,56.92a15.1463,15.1463,0,0,0-15.15,15.15v56.24a15.15,15.15,0,1,0,30.3,0V72.07A15.1463,15.1463,0,0,0,212.7418,56.92Z"
        />
        <path
          className="cls-7"
          d="M212.7418,88.67a11.52,11.52,0,1,0,11.52,11.52A11.523,11.523,0,0,0,212.7418,88.67Z"
        />
        <path
          className="cls-8"
          d="M211.8963,98.1643h-.0224l-1.2432.6713-.1865-.7373,1.5615-.8359h.8252v7.1509h-.9346Z"
        />
        <g id="down">
          <path
            className="cls-9"
            d="M212.7368,135.36a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-9"
            d="M212.7368,121.58a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,127.145l.3975.53-4.505,3.0475a.403.403,0,0,1-.53,0l-4.5049-3.0475.3975-.53,4.24,2.7825a.2484.2484,0,0,0,.3975,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-9"
            d="M212.7368,65.005a6.89,6.89,0,1,0,0,13.78,6.89,6.89,0,1,0,0-13.78Z"
          />
          <path
            className="cls-9"
            d="M212.7368,78.785a6.89,6.89,0,1,1,0-13.78,6.89,6.89,0,1,1,0,13.78Z"
          />
          <path
            className="cls-2"
            d="M217.2418,73.22l.3975-.53-4.505-3.0475a.403.403,0,0,0-.53,0L208.0994,72.69l.3975.53,4.24-2.7825a.2484.2484,0,0,1,.3975,0Z"
          />
        </g>
      </g>
      <g id="apartmentnr">
        <g
          className={`${
            sceneName === 'scene_Lounatuuli_1krs_as911_A'
              ? 'white-text'
              : elementOnHover === '911'
              ? 'green-text'
              : ''
          } ${
            getApartmentStatus(apartments, '911') === 'sold'
              ? 'text-white-sold'
              : ''
          }`}
          onClick={() => changeView(1, 911)}
          onMouseOver={() => setElementOnHover('911')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-2"
            d="M108.25,171.2209a2.5546,2.5546,0,0,0,.4971-.02,1.7189,1.7189,0,0,0,.9531-.4414,2,2,0,0,0,.6026-1.2119h-.0215a1.3341,1.3341,0,0,1-1.0713.49,1.3186,1.3186,0,0,1-1.3447-1.3935,1.5609,1.5609,0,0,1,1.5547-1.6182c.9316,0,1.5058.7568,1.5058,1.9189a2.8775,2.8775,0,0,1-.7842,2.1368,2.2845,2.2845,0,0,1-1.3242.6093,2.8713,2.8713,0,0,1-.5674.0352Zm.2247-2.6191a.8757.8757,0,0,0,.875.9668,1.0187,1.0187,0,0,0,.8964-.4766.4035.4035,0,0,0,.0567-.2236c0-.7774-.2871-1.3731-.9317-1.3731C108.8456,167.4953,108.4745,167.9572,108.4745,168.6018Z"
          />
          <path
            className="cls-2"
            d="M112.8211,167.677h-.0136l-.7911.4277-.1191-.47.9941-.5323h.5254v4.5528h-.5957Z"
          />
          <path
            className="cls-2"
            d="M116.4129,167.677h-.0146l-.791.4277-.1192-.47.9942-.5323h.5254v4.5528h-.5948Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  911: [141.6678, 169.0165],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #86cfc2;
  }

  .cls-2,
  .cls-8 {
    fill: #004a33;
  }

  .cls-3 {
    fill: #537f6e;
  }

  .cls-4,
  .cls-5,
  .cls-6 {
    fill: none;
  }

  .cls-4,
  .cls-5,
  .cls-6,
  .cls-8 {
    stroke: #004a33;
    stroke-miterlimit: 10;
  }

  .cls-4 {
    stroke-width: 1.5px;
  }

  .cls-5 {
    stroke-width: 0.85px;
  }

  .cls-6 {
    stroke-width: 0.3px;
  }

  .cls-7 {
    fill: #a5dacf;
  }

  .cls-8 {
    stroke-width: 0.75px;
  }

  .cls-9 {
    fill: #65c5b4;
  }

  .cls-10 {
    fill: url(#linear-gradient);
  }

  #up:hover {
    cursor: pointer;

    .cls-9 {
      fill: var(--secondary-color);
    }

    .cls-2 {
      fill: var(--green-color);
    }
  }

  .disable-lift-btn {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--grey);
    }

    .cls-6 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text .cls-2 {
    fill: var(--light);
    cursor: pointer;
  }

  .green-text .cls-2 {
    fill: var(--green-color);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }
`
