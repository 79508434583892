export const FLOOR_6_DATA = [
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 961,
    sceneName: 'scene_Lounatuuli_6krs_as961_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as961_A_future',
    name: '961',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      5: 951,
      7: 971,
      8: 981,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 962,
    sceneName: 'scene_Lounatuuli_6krs_as962_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as962_A_future',
    name: '962',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 932,
      4: 942,
      5: 952,
      7: 972,
      8: 982,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 963,
    sceneName: 'scene_Lounatuuli_6krs_as963_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as963_A_future',
    name: '963',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 923,
      3: 933,
      4: 943,
      5: 953,
      7: 973,
      8: 983,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 964,
    sceneName: 'scene_Lounatuuli_6krs_as964_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as964_A_future',
    name: '964',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 924,
      3: 934,
      4: 944,
      5: 954,
      7: 974,
      8: 984,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 965,
    sceneName: 'scene_Lounatuuli_6krs_as965_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as965_A_future',
    name: '965',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 935,
      4: 945,
      5: 955,
      7: 975,
      8: 985,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 966,
    sceneName: 'scene_Lounatuuli_6krs_as966_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as966_A_future',
    name: '966',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 936,
      4: 946,
      5: 956,
      7: 976,
      8: 986,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 967,
    sceneName: 'scene_Lounatuuli_6krs_as967_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as967_A_future',
    name: '967',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 937,
      4: 947,
      5: 957,
      7: 977,
      8: 987,
    },
  },
]
