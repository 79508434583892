export const FLOOR_4_DATA = [
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 941,
    sceneName: 'scene_Lounatuuli_4krs_as941_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as941_A_future',
    name: '941',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      5: 951,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 942,
    sceneName: 'scene_Lounatuuli_4krs_as942_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as942_A_future',
    name: '942',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 932,
      5: 952,
      6: 962,
      7: 972,
      8: 982,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 943,
    sceneName: 'scene_Lounatuuli_4krs_as943_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as943_A_future',
    name: '943',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 923,
      3: 933,
      5: 953,
      6: 963,
      7: 973,
      8: 983,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 944,
    sceneName: 'scene_Lounatuuli_4krs_as944_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as944_A_future',
    name: '944',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 924,
      3: 934,
      5: 954,
      6: 964,
      7: 974,
      8: 984,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 945,
    sceneName: 'scene_Lounatuuli_4krs_as945_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as945_A_future',
    name: '945',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 935,
      5: 955,
      6: 965,
      7: 975,
      8: 985,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 946,
    sceneName: 'scene_Lounatuuli_4krs_as946_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as946_A_future',
    name: '946',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 936,
      5: 956,
      6: 966,
      7: 976,
      8: 986,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 947,
    sceneName: 'scene_Lounatuuli_4krs_as947_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as947_A_future',
    name: '947',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 937,
      5: 957,
      6: 967,
      7: 977,
      8: 987,
    },
  },
]
