export const FLOOR_3_DATA = [
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 931,
    sceneName: 'scene_Lounatuuli_3krs_as931_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as931_A_future',
    name: '931',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      4: 941,
      5: 951,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 932,
    sceneName: 'scene_Lounatuuli_3krs_as932_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as932_A_future',
    name: '932',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 922,
      4: 942,
      5: 952,
      6: 962,
      7: 972,
      8: 982,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 933,
    sceneName: 'scene_Lounatuuli_3krs_as933_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as933_A_future',
    name: '933',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 923,
      4: 943,
      5: 953,
      6: 963,
      7: 973,
      8: 983,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 934,
    sceneName: 'scene_Lounatuuli_3krs_as934_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as934_A_future',
    name: '934',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 924,
      4: 944,
      5: 954,
      6: 964,
      7: 974,
      8: 984,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 935,
    sceneName: 'scene_Lounatuuli_3krs_as935_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as935_A_future',
    name: '935',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 925,
      4: 945,
      5: 955,
      6: 965,
      7: 975,
      8: 985,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 936,
    sceneName: 'scene_Lounatuuli_3krs_as936_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as936_A_future',
    name: '936',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 926,
      4: 946,
      5: 956,
      6: 966,
      7: 976,
      8: 986,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 937,
    sceneName: 'scene_Lounatuuli_3krs_as937_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as937_A_future',
    name: '937',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 927,
      4: 947,
      5: 957,
      6: 967,
      7: 977,
      8: 987,
    },
  },
]
