export const initialApartments = {
  1: {
    floor: 1,
    building: 'B',
    apartmentNumber: 911,
    sceneName: 'scene_Lounatuuli_1krs_as911_A',
    sceneName_future: 'scene_Lounatuuli_1krs_as911_A_future',
    name: '911',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 925,
      3: 935,
      4: 945,
      5: 955,
      6: 965,
      7: 975,
      8: 985,
    },
  },
  2: {
    floor: 2,
    building: 'B',
    apartmentNumber: 921,
    sceneName: 'scene_Lounatuuli_2krs_as921_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as921_A_future',
    name: '921',
    lookAt: '94, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      3: 931,
      4: 941,
      5: 951,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  3: {
    floor: 3,
    building: 'B',
    apartmentNumber: 931,
    sceneName: 'scene_Lounatuuli_3krs_as931_A',
    sceneName_future: 'scene_Lounatuuli_3krs_as931_A_future',
    name: '931',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 921,
      4: 941,
      5: 951,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  4: {
    floor: 4,
    building: 'B',
    apartmentNumber: 941,
    sceneName: 'scene_Lounatuuli_4krs_as941_A',
    sceneName_future: 'scene_Lounatuuli_4krs_as941_A_future',
    name: '941',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      5: 951,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  5: {
    floor: 5,
    building: 'B',
    apartmentNumber: 951,
    sceneName: 'scene_Lounatuuli_5krs_as951_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as951_A_future',
    name: '951',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  6: {
    floor: 6,
    building: 'B',
    apartmentNumber: 961,
    sceneName: 'scene_Lounatuuli_6krs_as961_A',
    sceneName_future: 'scene_Lounatuuli_6krs_as961_A_future',
    name: '961',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      5: 951,
      7: 971,
      8: 981,
    },
  },
  7: {
    floor: 7,
    building: 'B',
    apartmentNumber: 971,
    sceneName: 'scene_Lounatuuli_7krs_as971_A',
    sceneName_future: 'scene_Lounatuuli_7krs_as971_A_future',
    name: '971',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      5: 951,
      6: 961,
      8: 981,
    },
  },
  8: {
    floor: 8,
    building: 'B',
    apartmentNumber: 981,
    sceneName: 'scene_Lounatuuli_8krs_as981_A',
    sceneName_future: 'scene_Lounatuuli_8krs_as981_A_future',
    name: '981',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      5: 951,
      6: 961,
      7: 971,
    },
  },
}
