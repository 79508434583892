import * as React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'

import ArrowIcon from 'icons/arrow'
import { FLOORS_DATA } from 'data/floors_data'
import { initialApartments } from 'data/initialApartments'
import Collapsible from './Collapsible'
import Floor8 from 'floor-planes/floor8'
import Floor7 from 'floor-planes/floor7'
import Floor6 from 'floor-planes/floor6'
import Floor5 from 'floor-planes/floor5'
import Floor4 from 'floor-planes/floor4'
import Floor3 from 'floor-planes/floor3'
import Floor2 from 'floor-planes/floor2'
import Floor1 from 'floor-planes/floor1'
import { formatApartmentName } from 'utils/helper'

const floorComponents = {
  1: Floor1,
  2: Floor2,
  3: Floor3,
  4: Floor4,
  5: Floor5,
  6: Floor6,
  7: Floor7,
  8: Floor8,
}

const getHighestFloor = floorsData => {
  const unlockFloors = floorsData.length
    ? floorsData
        .filter(({ isOpen }) => isOpen === true)
        .map(({ number }) => number)
        .sort()
    : []
  const sortedUnlockFloors = _.sortBy(unlockFloors)
  const highestFloor = sortedUnlockFloors[sortedUnlockFloors.length - 1]
  return highestFloor
}

const getLowestFloor = floorsData => {
  const unlockFloors = floorsData.length
    ? floorsData
        .filter(({ isOpen }) => isOpen === true)
        .map(({ number }) => number)
        .sort()
    : []
  const sortedUnlockFloors = _.sortBy(unlockFloors)
  const lowestFloor = sortedUnlockFloors[0]
  return lowestFloor
}

function getAvailableApartments(apartments, floorNumber) {
  const apartmentsOnThisFloor = apartments.filter(
    apartment => apartment.floor === floorNumber,
  )
  const availableApartments = apartmentsOnThisFloor.filter(
    apartment => apartment.status !== 'sold',
  )

  return availableApartments
}

export default function NavigationPanel({
  krpano,
  hLookAt,
  apartments,
  floorsData,
}) {
  const highestFloor = getHighestFloor(floorsData)
  const lowestFloor = getLowestFloor(floorsData)
  const unlockFloors = floorsData.length
    ? floorsData
        .filter(({ isOpen }) => isOpen === true)
        .map(({ number }) => number)
        .sort()
    : []
  const sortedUnlockFloors = _.sortBy(unlockFloors)
  const [activeFloor, setActiveFloor] = React.useState(highestFloor)
  const [activeApartment, setActiveApartment] = React.useState(
    initialApartments[highestFloor],
  )
  const [collapse, setCollapse] = React.useState(false)
  const [index, setIndex] = React.useState(undefined)
  const [isSceneNameFuture, setIsSceneNameFuture] = React.useState(false)
  const [currentFace, setCurrentFace] = React.useState(
    initialApartments[highestFloor].face,
  )
  const floors = Object.keys(FLOORS_DATA)

  React.useEffect(() => {
    if (
      window.matchMedia('(orientation: landscape)').matches &&
      window.matchMedia('(max-device-width: 667px)').matches
    ) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }, [])

  const loadScene = React.useCallback(
    (sceneName, lookAt, activeFace) => {
      if (krpano) {
        const hlookat = krpano.get('view.hlookat')
        const vlookat = krpano.get('view.vlookat')
        const fov = krpano.get('view.fov')
        const distortion = krpano.get('view.distortion')

        krpano.call(
          `loadscene(${sceneName}, null, MERGE, BLEND(0.5, easeInCubic)); lookat(${lookAt});`,
        )

        if (currentFace === activeFace) {
          krpano.set('view.hlookat', hlookat)
          krpano.set('view.vlookat', vlookat)
          krpano.set('view.fov', fov)
          krpano.set('view.distortion', distortion)
        }
      }
    },
    [currentFace, krpano],
  )

  const changeView = React.useCallback(
    (floorNumber, aptNumber) => {
      const apartment = FLOORS_DATA[floorNumber].find(
        e => e.apartmentNumber === aptNumber,
      )
      const lookAt = apartment.lookAt
      const activeFace = apartment.face
      const sceneName = isSceneNameFuture
        ? apartment.sceneName_future
        : apartment.sceneName

      setActiveFloor(floorNumber)
      setActiveApartment(apartment)
      setCurrentFace(activeFace)
      loadScene(sceneName, lookAt, activeFace)
    },
    [isSceneNameFuture, loadScene],
  )

  const changeViewToAvailableApartmentOnly = React.useCallback(
    (floorNumber, aptNumber) => {
      const apartmentsOnThisFloor = apartments.filter(
        apartment => apartment.floor === floorNumber,
      )
      const availableApartments = apartmentsOnThisFloor.filter(
        apartment => apartment.status !== 'sold',
      )
      const chosenApartmentStatus = apartmentsOnThisFloor.find(
        apartment => apartment.number === aptNumber,
      )?.status
      const isSoldApartment = chosenApartmentStatus === 'sold' || false

      if (isSoldApartment) {
        if (!availableApartments.length && floorNumber === highestFloor) {
          const availableApartmentsOnPreviousFloor = getAvailableApartments(
            apartments,
            highestFloor - 1,
          )
          const sortedApartmentsByNumber = _.sortBy(
            availableApartmentsOnPreviousFloor,
            ['number'],
          )
          const goToApartment =
            sortedApartmentsByNumber[sortedApartmentsByNumber.length - 1].number

          changeView(highestFloor - 1, goToApartment)
        } else {
          const goToApartment = availableApartments[0].number
          changeView(floorNumber, goToApartment)
        }
      } else {
        changeView(floorNumber, aptNumber)
      }
    },
    [apartments, changeView, highestFloor],
  )

  const changeFloor = navCase => {
    const { apartmentNumber, relatedApartments, floor } = activeApartment
    const targetedFloor = changeActiveFloor(navCase) || floor
    const targetedApartmentNumber =
      relatedApartments[targetedFloor] || apartmentNumber
    // getRelatedApartment(relatedApartments, targetedFloor)
    changeViewToAvailableApartmentOnly(targetedFloor, targetedApartmentNumber)
  }

  const changeActiveFloor = navCase => {
    let targetedFloor

    if (
      navCase === 'up' &&
      sortedUnlockFloors.indexOf(activeFloor) < sortedUnlockFloors.length - 1
    ) {
      const nextIndex = sortedUnlockFloors.indexOf(activeFloor) + 1
      targetedFloor = sortedUnlockFloors[nextIndex]
      const availableApartments = getAvailableApartments(
        apartments,
        targetedFloor,
      )

      if (!availableApartments.length && targetedFloor === highestFloor) {
        setActiveFloor(activeFloor)
      } else if (!availableApartments.length) {
        setActiveFloor(targetedFloor + 1)
        targetedFloor = targetedFloor + 1
      } else {
        setActiveFloor(targetedFloor)
      }
    }
    if (navCase === 'down' && sortedUnlockFloors.indexOf(activeFloor) > 0) {
      const nextIndex = sortedUnlockFloors.indexOf(activeFloor) - 1
      targetedFloor = sortedUnlockFloors[nextIndex]
      const availableApartments = getAvailableApartments(
        apartments,
        targetedFloor,
      )

      if (!availableApartments.length && targetedFloor === lowestFloor) {
        setActiveFloor(activeFloor)
      } else if (!availableApartments.length) {
        setActiveFloor(targetedFloor - 1)
        targetedFloor = targetedFloor - 1
      } else {
        setActiveFloor(targetedFloor)
      }
    }
    return targetedFloor
  }

  const changeApartInCollapsible = navCase => {
    const apartments = Object.values(FLOORS_DATA[activeFloor]).map(
      e => e.apartmentNumber,
    )
    const { apartmentNumber } = activeApartment
    const apartmentIndex = apartments.indexOf(apartmentNumber)

    if (navCase === 'next' && apartmentIndex < apartments.length - 1) {
      setIndex(apartmentIndex + 1)
      changeViewToAvailableApartmentOnly(
        activeFloor,
        apartments[apartmentIndex + 1],
      )
    }
    if (navCase === 'previous' && apartmentIndex > 0) {
      changeViewToAvailableApartmentOnly(
        activeFloor,
        apartments[apartmentIndex - 1],
      )
      setIndex(apartmentIndex - 1)
    }
  }

  React.useEffect(() => {
    if (krpano) {
      changeViewToAvailableApartmentOnly(
        activeFloor,
        activeApartment.apartmentNumber,
      )
    }
  }, [
    activeApartment.apartmentNumber,
    activeFloor,
    changeViewToAvailableApartmentOnly,
    krpano,
  ])

  const toggleSceneNameFuture = () => setIsSceneNameFuture(!isSceneNameFuture)

  const floorPlanProps = {
    activeApartment,
    changeFloor,
    changeView,
    hLookAt,
    toggleSceneNameFuture,
    isSceneNameFuture,
    apartments,
  }

  const renderUnlockFloorComponents = () => {
    return !!sortedUnlockFloors
      ? sortedUnlockFloors.map(floorNumber => {
          const UnlockFloor = floorComponents[floorNumber]

          return (
            <UnlockFloor
              key={floorNumber}
              floor={floorNumber}
              {...floorPlanProps}
            />
          )
        })
      : null
  }

  return (
    <Wrapper>
      <Collapsible collapse={collapse} setCollapse={setCollapse}>
        {collapse ? (
          <ApartmentInfo>
            <ElevatorIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  onClick={() => changeFloor('up')}
                  disabled={activeFloor === Number(floors[floors.length - 1])}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="180deg"
                  onClick={() => changeFloor('down')}
                  disabled={activeFloor === Number(floors[0])}
                />
              </div>
            </ElevatorIcons>
            <p className="floor">Kerros {activeApartment.floor}</p>
            <p className="apartment">
              Huoneisto {formatApartmentName(activeApartment.name)}
            </p>
            <ApartmentIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  rotateDeg="-90deg"
                  onClick={() => changeApartInCollapsible('next')}
                  disabled={index === 3}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="90deg"
                  onClick={() => changeApartInCollapsible('previous')}
                  disabled={index === 0}
                />
              </div>
            </ApartmentIcons>
          </ApartmentInfo>
        ) : (
          <div style={{ position: 'relative' }}>
            <SwitchComponents active={activeFloor}>
              {renderUnlockFloorComponents()}
            </SwitchComponents>

            <Link
              href="https://tietoa.fi/palvelut/sujuvat-sovellukset/tietoa-360-maisemakone/"
              target="_blank"
            >
              Tietoa 360 Maisemakone
            </Link>

            {/* <UploadDataButton
              collectionName="finnoo-lounatuuli-A"
              projectName="Finnoo - Lounatuuli 9"
              company="bonava"
            /> */}
          </div>
        )}
      </Collapsible>
    </Wrapper>
  )
}

function SwitchComponents({ active, children }) {
  return !!children
    ? children.filter(child => child.props.floor === active)
    : null
}

const Link = styled.a`
  position: absolute;
  right: -65px;
  top: 90px;
  transform: rotate(270deg);
  font-size: 13px;
  opacity: 0.5;
  color: #3f3f3c;
  user-select: none;
  text-decoration: none;

  @media (max-width: 575px) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 510px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  background-color: #86cfc2;
  z-index: 2;

  @media (max-width: 575px) {
    right: 0;
    width: 100%;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }
`

const ApartmentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    min-width: 50px;
    margin: 0;
    padding: 10px 20px;
    color: var(--light);
    font-size: 14px;
  }

  .floor {
    background-color: var(--green-color);
    color: var(--light);
  }

  .apartment {
    min-width: 120px;
    margin-right: 0;
    background-color: var(--primary-color);
    color: var(--light);
  }

  @media (max-width: 575px) {
    p {
      padding: 5px 10px;
      font-size: 10px;
    }

    .apartment {
      min-width: 80px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--secondary-color-80);
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--secondary-color-80);
    }
  }
`

const ElevatorIcons = styled.div`
  display: flex;
  margin-right: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-right: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }
`
const ApartmentIcons = styled.div`
  display: flex;
  margin-left: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-left: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }
`
