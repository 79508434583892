export const FLOOR_1_DATA = [
  {
    floor: 1,
    building: 'B',
    apartmentNumber: 911,
    sceneName: 'scene_Lounatuuli_1krs_as911_A',
    sceneName_future: 'scene_Lounatuuli_1krs_as911_A_future',
    name: '911',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      2: 925,
      3: 935,
      4: 945,
      5: 955,
      6: 965,
      7: 975,
      8: 985,
    },
  },
]
