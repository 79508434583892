export const FLOOR_5_DATA = [
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 951,
    sceneName: 'scene_Lounatuuli_5krs_as951_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as951_A_future',
    name: '951',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 931,
      4: 941,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 952,
    sceneName: 'scene_Lounatuuli_5krs_as952_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as952_A_future',
    name: '952',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 921,
      3: 932,
      4: 942,
      6: 962,
      7: 972,
      8: 982,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 953,
    sceneName: 'scene_Lounatuuli_5krs_as953_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as953_A_future',
    name: '953',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 923,
      3: 933,
      4: 943,
      6: 963,
      7: 973,
      8: 983,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 954,
    sceneName: 'scene_Lounatuuli_5krs_as954_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as954_A_future',
    name: '954',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 924,
      3: 934,
      4: 944,
      6: 964,
      7: 974,
      8: 984,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 955,
    sceneName: 'scene_Lounatuuli_5krs_as955_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as955_A_future',
    name: '955',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 935,
      4: 945,
      6: 965,
      7: 975,
      8: 985,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 956,
    sceneName: 'scene_Lounatuuli_5krs_as956_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as956_A_future',
    name: '956',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 936,
      4: 946,
      6: 966,
      7: 976,
      8: 986,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 957,
    sceneName: 'scene_Lounatuuli_5krs_as957_A',
    sceneName_future: 'scene_Lounatuuli_5krs_as957_A_future',
    name: '957',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      2: 925,
      3: 937,
      4: 947,
      6: 967,
      7: 977,
      8: 987,
    },
  },
]
