export const FLOOR_2_DATA = [
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 921,
    sceneName: 'scene_Lounatuuli_2krs_as921_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as921_A_future',
    name: '921',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      3: 931,
      4: 941,
      5: 951,
      6: 961,
      7: 971,
      8: 981,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 922,
    sceneName: 'scene_Lounatuuli_2krs_as922_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as922_A_future',
    name: '922',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      3: 932,
      4: 942,
      5: 952,
      6: 962,
      7: 972,
      8: 982,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 923,
    sceneName: 'scene_Lounatuuli_2krs_as923_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as923_A_future',
    name: '923',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      3: 933,
      4: 943,
      5: 953,
      6: 963,
      7: 973,
      8: 983,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 924,
    sceneName: 'scene_Lounatuuli_2krs_as924_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as924_A_future',
    name: '924',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      3: 934,
      4: 944,
      5: 954,
      6: 964,
      7: 974,
      8: 984,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 925,
    sceneName: 'scene_Lounatuuli_2krs_as925_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as925_A_future',
    name: '925',
    lookAt: '93, 7, 120',
    face: 'east',

    relatedApartments: {
      1: 911,
      3: 935,
      4: 945,
      5: 955,
      6: 965,
      7: 975,
      8: 985,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 926,
    sceneName: 'scene_Lounatuuli_2krs_as926_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as926_A_future',
    name: '926',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      3: 936,
      4: 946,
      5: 956,
      6: 966,
      7: 976,
      8: 986,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 927,
    sceneName: 'scene_Lounatuuli_2krs_as927_A',
    sceneName_future: 'scene_Lounatuuli_2krs_as927_A_future',
    name: '927',
    lookAt: '-85.7, 7, 120',
    face: 'west',

    relatedApartments: {
      1: 911,
      3: 937,
      4: 947,
      5: 957,
      6: 967,
      7: 977,
      8: 987,
    },
  },
]
